import { defineComponent, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import axios from 'axios';
import { useMessageBox, withLoading } from '@hems/component';
import { AuthService } from '@hems/service';
import { FindIdPwPopup } from '@/components';
const API_BASE_URL = process.env.VUE_APP_API_URL;
export default defineComponent({
    name: 'FindIdPwPopupContainer',
    components: {
        FindIdPwPopup,
    },
    props: {
        langCd: String,
    },
    setup(props) {
        const axiosInstance = axios.create({
            baseURL: API_BASE_URL,
        });
        const authService = new AuthService(axiosInstance);
        const messageBox = useMessageBox();
        const { t } = useI18n();
        const result = ref(null);
        function findId(email) {
            withLoading(async () => {
                try {
                    const resData = await authService.findId(email);
                    if (resData.status === 'error') {
                        messageBox.alert(t('message.invalid_id_pw'));
                        return;
                    }
                    result.value = `${resData.result.user_id.substring(0, resData.result.user_id.length - 2)}**`;
                }
                catch (e) {
                    messageBox.alert(t('message.unknown_error')).open();
                }
            })();
        }
        function resetPw(user_id, email) {
            withLoading(async () => {
                try {
                    const resData = await authService.resetPassword({
                        langCd: props.langCd || 'en',
                        user_id,
                        email,
                    });
                    if (resData.status !== 'ok') {
                        messageBox.alert(t('message.invalid_id_pw'));
                        return;
                    }
                    messageBox.alert(t('message.reset_pw')).open();
                }
                catch (e) {
                    messageBox.alert(t('message.unknown_error')).open();
                }
            })();
        }
        function clear() {
            result.value = null;
        }
        return {
            findId,
            resetPw,
            clear,
            result,
        };
    },
});
